<template lang="pug">
  div
    base-snackbar(
      :errors.sync="orderDetailStatusGetErrors"
    )
    v-toolbar(
      color="primary"
      dark
      dense
      elevation="0"
    )
      span Statuses
    v-data-table(
      :items="orderDetailStatusList"
      :headers="headers"
      :loading="orderDetailStatusGetting"
      dense
    )
      template(v-slot:item="{ item }")
        tr
          td {{ item.id }}
          td {{ item.name }}
          td {{ item.created_at.dateFormat({ hour: '2-digit', minute: '2-digit' }) }}
          td {{ item.updated_at.dateFormat({ hour: '2-digit', minute: '2-digit' }) }}
</template>
<script>
import VueGetApi from '@/libs/classes/VueGetApi.class'
import { getVars } from '@/libs/api-helper.extra'
import orderDetailStatusRepository from '@/repositories/order-detail-status.repository'

const tableVars = () => ({
  headers: [
    { text: 'ID', value: 'id' },
    { text: 'Name', value: 'name' },
    { text: 'Created', value: 'created_at' },
    { text: 'Updated', value: 'updated_at' },
  ],
})

export default {
  name: 'OrderDetailStatusTable',
  created() {
    this.getOrderDetailStatus()
    this.initWebsocketEvents()
  },
  data: () => ({
    ...tableVars(),
    ...getVars('order-detail-status'),
  }),
  methods: {
    getOrderDetailStatus() {
      if (this.orderDetailStatusGetting) return
      const getRequestVariableNames = this.getRequestVariableNames()
      const getRequestApi = new VueGetApi(this, getRequestVariableNames)
      const repository = orderDetailStatusRepository.index()
      getRequestApi.fetch(repository)
    },
    getRequestVariableNames() {
      const BASE_NAME = 'orderDetailStatus'
      return {
        loading: `${BASE_NAME}Getting`,
        error: `${BASE_NAME}GetErrors`,
        list: `${BASE_NAME}List`,
      }
    },
    initWebsocketEvents() {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
    },
    dbStoreEvent({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'OrderDetailStatus') {
        this.orderDetailStatusDBStoreEvent(data)
      }
    },
    orderDetailStatusDBStoreEvent(data) {
      this.orderDetailStatusList = this.orderDetailStatusList
        .map(item => ({ ...item }))
        .objectInsertion(data, 'id', 'id')
    },
  },
}
</script>
